<template>
    <div class="base cadre"
         v-if="charge"
    >
        <div class="container cadre-content">
            <h2>Signature Electronique</h2>


            <div class="cadre block-signataires">
                <div class="cadre-tete cadre-tete--menu">
                    Signataires
                </div>
                <div class="cadre-content">
                    <div
                        class="signataire"
                        v-for="signataire in signataires"
                        :key="signataire.id"
                    >
                        <div class="signataire-nom">
                            {{signataire.prenom}} {{signataire.nom}}
                        </div>
                        <div class="signataire-statut">
                            <a
                                v-if="signataire.signe === '0'"
                                class="btn btn-default"
                                :href="signataire.url"
                                target="_blank"
                            >
                                <span> Signer </span>
                            </a>
                            <span
                                class="text-success"
                                v-if="signataire.signe === '1'"
                            >
                                <i class="fas fa-check"></i> A Signé
                            </span>
                        </div>
                    </div>
                    <button
                        class="btn btn-default"
                        type="button"
                        style="width: 100%; max-width: 100%;"
                        @click="fetchSignatures"
                    > Rafraichir les statuts signature
                    </button>
                </div>
            </div>
        </div>

        <div class="cadre-pied background-white"
            v-if="!$compte.client"
        >
            <button
                class="btn btn-error"
                type="button"
                @click="procedureAbandonnerAction"
            >
                Annuler la procédure
            </button>
            <button
                class="btn btn-default"
                type="button"
                @click="procedureFermer"
            > Fermer
            </button>
        </div>
        <modal
            v-if="procedureAbandonner"
            type="error"
        >
            <template slot="header">
                Confirmez l'annulation
            </template>
            <template slot="content">
                <p>Veuillez confirmer l'annulation de la procédure.</p>
                <br>
                <p>Seules les signatures non administrées pourront être recréditées à votre solde.</p>
            </template>
            <template slot="footer">
                <button
                    class="btn btn-transparent"
                    @click="procedureAbandonner = false"
                >Abandonner
                </button>
                <button
                    class="btn btn-transparent"
                    @click="procedureAnnuler"
                >Confirmer
                </button>
            </template>
        </modal>
        <modal
            v-if="procedureTerminer"
        >
            <template slot="header">
                Procédure terminée
            </template>
            <template slot="content">
                <p>Tous vos signataires ont signé la procédure. Celle-ci est donc désormais terminée!</p>
            </template>
            <template slot="footer">
                <button
                    class="btn btn-transparent btn-large"
                    type="button"
                    @click="procedureFermer"
                > Fermer
                </button>
            </template>
        </modal>
    </div>

</template>

<script>
  import api from '@/api'

  export default {
    name: 'ProcedureGerer',
    data() {
      return {
        signataires: [],
        documents: [],
        procedureAbandonner: false,
        procedureTerminer: false,
        charge: false,
      }
    },
    created() {
        this.fetchSignatures()

        console.log('Chargement de la procedure (gérer)');
    },
    methods: {
      fetchSignatures() {
        api.signaturesCharger(this.$dossier.id).then((d) => {
            const {documents, signataires, termine} = d.data
            this.document = documents
            this.signataires = signataires

            if(this.$compte.client)
              this.signataires = this.signataires.filter((s) => s.type !== 'COURTIER')

            this.procedureTerminer = termine
            this.charge = true
        }, () => {
            this.procedureTerminer = true
            this.charge = true
        })

      },
      procedureAbandonnerAction(e) {
        e.preventDefault()
        this.procedureAbandonner = true
      },
      procedureAnnuler(e) {
        e.preventDefault()
        api.procedureAnnuler(this.$dossier.id).then((d) => {
          this.procedureAbandonner = false
          
          this.$root.$emit('compteRafraichir', () => {
            this.$router.push({name: 'ProcedureCreer'})
          })
        })
      },
      procedureFermer() {
        /* Ferme la fenêtre via l'evènement du bouton fermer */
        var boutons = document.getElementsByClassName('window_close');
        var e = document.getElementsByClassName('window_close')[boutons.length - 1];
        e.click();
      }
    }
  }
</script>

<style lang="scss" scoped>

    @import "../assets/scss/colors";

    .base {
        background: $gray-lighter !important;
    }

    .container {
        padding: 20px;
    }

    .cadre {
        height: auto !important;
        background: white;
    }

    h2 {
        font-weight: 100;
    }

    .block-documents {
        margin-bottom: 20px;
    }

    .cadre-tete.cadre-tete--menu {
        margin: 10px 20px 0 !important;
    }

    .cadre-pied {
        border-top: 1px solid $gray-light;
    }

    .signataire {
        display: flex;
        border: 1px solid $gray-light;
        margin-bottom: 20px;
        justify-content: space-between;
        padding: 10px;
        height: 40px;
        align-items: center;
    }

    .signataire-nom {
        flex: 1;
    }

    .signataire-statut {
        flex-basis: 30%;
        text-align: center;

        a {
            justify-content: center;
        }
    }

    .fa-check {
        vertical-align: middle;
    }

    a.btn {
        text-decoration: none;
        display: flex;
        align-items: center;
        font-size: .75rem;
    }

    .btn-error {
        color: $error;
        border-color: $error;
    }

</style>
