<template>
    <modal>
        <template slot="header">Compléter la commande</template>
        <template slot="content">
            <p>Votre première commande nécessite la signature d'un avenant.</p>
            <br>
            <p>Cette étape ne sera plus nécessaire lors de vos prochaines commandes.</p>
            <br>
            <p>
                Vous pouvez procéder à la signature de l'avenant électroniquement directement, 
                ou via l'email que recevrez prochainement
            </p>
            <br>
            <p style="background: #f9f9f9;padding: 15px;border: 1px solid #eee;">
              Une fois la signature effectuée, cliquer sur <b style="font-weight: 900; color: #111">Réaliser ma première procédure</b> pour poursuivre la procédure
            </p>
        </template>
        <template slot="footer">
            <a
                class="btn btn-transparent btn-large"
                target="_blank"
                :disabled="!urlAvenant"
                :href="urlAvenant"
            >
                Signer
            </a>
            <a
                class="btn btn-transparent btn-large"
                @click="close"
                style="margin-left: 20px"
            >
                Réaliser ma première procédure
            </a>
        </template>
    </modal>
</template>

<script>

  import api from '@/api'

  export default {
    name: 'ModalAvenant',
    props: {
      close: Function,
    },
    data() {
      return {
        urlAvenant: null,
      }
    },
    created() {
      this.signatureAvenantVerifier()
    },
    methods: {
      signatureAvenantVerifier() {
        api.avenantSignatureRecuperer().then((d) => {
          this.urlAvenant = d.data.url;
        })
      },
    }
  }
</script>

<style lang="scss" scoped>
    a.btn {
        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    p {
        margin: 0 !important;
    }
</style>
